import {Injectable} from "@angular/core";
import {CtModelConfiguration, CTModelDatatableFilter} from "@ctsolution/ct-framework";
import {ControllerHelper} from "./controller.helper";
import {CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";

const CONTROLLER: string[] = ["warranty"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class WarrantyController {

    constructor(
        private _helper: ControllerHelper,
        private _webapi: CtWebapiService) {
    }

    get = <T>(filters: CTModelDatatableFilter[]): Promise<CtModelConfiguration<Array<any>>> => this._helper.list(CONTROLLER_REQUEST()
        .setBody({
            Filters: filters
        }));

}
