<mat-card class="contract-info-field-cntr">

    <mat-card-content [formGroup]="form">

        <mat-card-title>

            Tipologia, Codice Contratto e Descrizione

        </mat-card-title>

        <div class="control-cntr">

            <app-contract-type-control
                    (setupContractTypeExtraField)="setupContractTypeExtraField.emit($event)"></app-contract-type-control>

        </div>

        <div class="control-cntr">

            <app-contract-code-control></app-contract-code-control>

        </div>

        <div class="control-cntr">
            <app-contract-description-control></app-contract-description-control>
        </div>

    </mat-card-content>

</mat-card>
