import { ContractRenewDialogService } from './contract-renew-dialog/contract-renew-dialog.service';
import { ContractRenewDialogComponent } from './contract-renew-dialog/contract-renew-dialog.component';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
    CtButtonConfiguration,
    CTGeneralService, CtModelConfiguration,
    CtModelService, FormHelperService,
    MAT_RAISED_PRIMARY,
    MAT_RAISED_WARN, SnackbarService
} from "@ctsolution/ct-framework";
import { CtWebapiGenericResponse, CtWebapiService } from "@ctsolution/ct-webapi";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { Currency } from 'src/app/core/enum/currency';
import { ContractDTO } from "../../../core/class/contract-dto";
import { ContractTypeListElementDTO } from "../../../core/controller/contract-types.controller";
import { ContractController } from "../../../core/controller/contract.controller";
import { RoleService } from "../../../core/lib/role.service";
import { CONTRACT_ROUTEDATA } from "../contract.module";
import { ContractAlertFieldComponent } from "./contract-alert-field/contract-alert-field.component";
import { ContractDetailFieldComponent } from "./contract-detail-field/contract-detail-field.component";
import { ContractExtraFieldComponent } from "./contract-extra-field/contract-extra-field.component";
import { ContractExtraFieldConfiguration } from "./contract-extra-field/contract-extra-field.configuration";
import { ContractInfoFieldComponent } from "./contract-info-field/contract-info-field.component";
import { ContractRenewFieldComponent } from "./contract-renew-field/contract-renew-field.component";
import { ContractState } from 'src/app/core/enum/contract-state';
import { InvoiceType } from 'src/app/core/enum/invoice-type';

@Component({
    selector: 'app-contract-create',
    templateUrl: './contract-create.component.html',
    styleUrls: ['./contract-create.component.scss'],
    providers: [ContractController]
})
export class ContractCreateComponent<T> {

    @ViewChild('ContractExtraField') contractExtraField: ContractExtraFieldComponent | null = null;
    @ViewChild('ContractMonitoringField') contractMonitoringField: ContractAlertFieldComponent | null = null;
    @ViewChild('ContractDetailField') contractDetailField: ContractDetailFieldComponent | null = null;
    @ViewChild('ContractInfoField') contractInfoField: ContractInfoFieldComponent | null = null;
    @ViewChild('ContractRenewField') contractRenewField: ContractRenewFieldComponent | null = null;

    cancelButton: CtButtonConfiguration = CtButtonConfiguration
        .create()
        .setClass('m-r-10')
        .setLabel("CT_PAGE.CT_FORM.cancel")
        .setAction(() => this.onCancel())
        .setMatherialOptions(MAT_RAISED_WARN)

    submitButton: CtButtonConfiguration = CtButtonConfiguration
        .create()
        .setLabel("CT_PAGE.CT_FORM.save")
        .setAction(() => this.onSubmit())
        .setMatherialOptions(MAT_RAISED_PRIMARY);

    configuration: CtModelConfiguration<ContractDTO<T>> = CtModelConfiguration
        .create<ContractDTO<T>>()
        .setRouteData(CONTRACT_ROUTEDATA());

    form: FormGroup;

    states = ContractState;

    viewModel = {
        isTech: false
    }

    constructor(
        private formBuilder: FormBuilder,
        private _webapi: CtWebapiService,
        private general: CTGeneralService,
        private _model: CtModelService<ContractDTO<T>>,
        private route: ActivatedRoute,
        private formHelper: FormHelperService,
        private cdr: ChangeDetectorRef,
        private contractController: ContractController,
        private _snackbar: SnackbarService,
        private roleService: RoleService,
        private router: Router,
        private contractRenewDialogService: ContractRenewDialogService 
    ) {

        this.form = this.formBuilder.group({});

        this.form
            .valueChanges
            .pipe(debounceTime(100), distinctUntilChanged())
            .subscribe(value => this.contractInfoField?.contractCodeCtrl?.setupContractCodeByFormValue(value))

    }
private prevInvoceType?:number;
private prevMonthDuration?:number;
    ngAfterViewInit() {

        this.form
            .addControl('Info', this.contractInfoField?.form);

        this.form
            .addControl('Detail', this.contractDetailField?.form);

        this.form.controls['Detail'].valueChanges.subscribe((value) =>
        {
            if(this.form.controls['Detail'].valid){
                this.contractExtraField?.setFormCustomError(false, '');
                this.contractExtraField?.setCustomFormDisabled(false);
            }else{
                this.contractExtraField?.setFormCustomError(true, 'Per poter compilare questa parte del form è necessario compilare correttamente i campi precedenti');
            }

        });
        this.contractDetailField?.form.valueChanges.subscribe((value) =>{

            var nextInvoceType =value['InvoiceType'];
            var nextMonthDuration = value['MonthDuration'];

            if(this.contractExtraField?.formContainer && (nextInvoceType != this.prevInvoceType || nextMonthDuration != this.prevMonthDuration)){
                this.prevInvoceType = value['InvoiceType'];
                this.prevMonthDuration = value['MonthDuration'];
                Object.entries(<any>this.contractExtraField?.formContainer?.form?.controls).forEach(([key, control]) => {
                    console.info(key);
                    this.contractExtraField?.toAutoIncrementAmountFunc(
                        {lstSoftwareSubscriptionRowDTO:this.contractExtraField?.formContainer?.form?.controls["lstSoftwareSubscriptionRowDTO"].value});
                } )
            }
        });
        this.setupPermissions()
            .then(() => this.setupContract());

        this.cdr.detectChanges();

    }

    private async setupPermissions() {
        this.viewModel.isTech = await this.roleService.is.tech();
        
        if (this.viewModel.isTech) {

            this.disableForm();

        }
    }

    setupContract() {

        const paramId: string | null = this.route.snapshot.paramMap.get("id");

        if (paramId) {

            this.configuration
                ?.RouteData
                ?.setId(+paramId);

            this._model
                .getInfos(this.configuration)
                ?.subscribe((response: CtWebapiGenericResponse<CtModelConfiguration<ContractDTO<T>>>) => {

                    const value = response.Result.DataSource as ContractDTO<T>;

                    if (!value) return;

                    this.contractInfoField?.contractDescriptionCtrl?.control?.setValue(value.Description);

                    value.User = (<any>value.User)?.CommercialCode;
                    value.Customer = (<any>value.Customer)?.Oid;
                    value.Supplier = (<any>value.Supplier)?.Oid;

                    value.StartDate = new Date(value.StartDate ?? 0);

                    this.setupContractValue(value)

                });
        }
    }

    async setupContractValue(value: ContractDTO<any>) {
            if ((value.ContractNumber ?? 0) <= 0) value.ContractNumber = null;
    
            value.InvoiceCurrency == 'CHF' ? value.Currency = Currency.CHF : value.Currency = Currency.EUR;
    
            this.contractDetailField
                ?.form
                .patchValue(value, {emitEvent: false});
            
            this.contractDetailField?.monthDurationControlComponent?.control.setValue(value.MonthDuration);
    
            this.contractDetailField?.setupCustomerChangeSubscription();
            //Forzata la chiamata per non togliere l'emitEvent a false, non sapendo cosa comporta
    
            this.contractRenewField
            ?.form
            .patchValue(value);
    
            this.contractMonitoringField
            ?.form
            .patchValue(value);
    
            
            if (value.ContractType) {
                
                this.contractInfoField
                ?.contractTypeCtrl
                ?.setContractTypeValueByOid(value.ContractType.Oid);
                
            }
            
            this.contractExtraField
            ?.setFormValue(value.SpecificContract);
            
            var state = this.contractDetailField?.form.get('State')?.value;
            if (state == this.states.Replaced) {
    
                this.disableForm(value);
    
            }
    }

    updateAmount(event: number | null = null) {
        if (!!event) {
            this.contractDetailField
                ?.setTotalValue(event, this.contractDetailField.form.value['MonthDuration']);

        }

    }

    setupContractTypeExtraField(contractType: ContractTypeListElementDTO | null) {

        const extraFieldConfiguration: ContractExtraFieldConfiguration = ContractExtraFieldConfiguration
            .create()
            .setContractType(contractType);

        this.contractExtraField
            ?.setup(extraFieldConfiguration);

        this.contractDetailField
            ?.toggleTotalAmountValidation(contractType);

        if (contractType?.HasAlertAndMonitoring && !this.form.get('Monitoring')) {

            this.form
                .addControl('Monitoring', this.contractMonitoringField?.form);

        } else if (this.form.get('Monitoring')) {

            this.form.removeControl('Monitoring');

        }

        this.contractExtraField?.setFormCustomError(false, '');

        this.form.updateValueAndValidity();

        this.cdr.detectChanges();

    }

    onCancel() {

        this.general
            .back();

    }

    onSubmit(renew: boolean = false) {

        this.form.markAllAsTouched();
        this.contractExtraField?.formContainer?.form?.markAllAsTouched();

        if (this.form.valid && this.contractExtraField?.formContainer?.form?.valid) {

            const contractDetail = this.form.get('Detail')?.getRawValue(); // prende anche i valori disabilitati

            const contract: ContractDTO<T> = ContractDTO
                .create(contractDetail);

            contract
                .setUser(contractDetail.User)
                .setCustomer(contractDetail.Customer)
                .setSupplier(contractDetail.Supplier);

            const contractInfo = this.form.get('Info')?.getRawValue();

            if (contractInfo) contract.setContractType(+contractInfo.ContractType.Code);

            contract.setDescription(contractInfo.Description);

            if (this.form.value.Monitoring) {

                const contractInfo = this.form.value.Monitoring;

                contract
                    .setMonitoring(contractInfo.Monitoring)
                    .setAlertManagement(contractInfo.AlertManagement)
                    .setAlertEmail(contractInfo.AlertEmail)

            }

            const specificContractFields = this.contractExtraField?.formContainer?.form?.getRawValue();

            if (this.contractExtraField) {

                contract
                    .setSpecificContract(specificContractFields);

            }

            if (renew) {
                var dialog = this.contractRenewDialogService.open()
                dialog.afterClosed().subscribe((val) => {
                    if(val == true) this.renewContract(contract);
                });
                return;
            }
            //const os = date.getTimezoneOffset();
            //return new Date(date.getTime() - os * 60 * 1000);
            if(contract.StartDate){
                contract.StartDate = new Date(contract.StartDate.getTime() - contract.StartDate.getTimezoneOffset() * 60 * 1000);
            }
            if(contract.EndDate){
                contract.EndDate = new Date(contract.EndDate.getTime() - contract.EndDate.getTimezoneOffset() * 60 * 1000);
            }
            this._model
                .putInfos(this.configuration, contract)
                ?.subscribe(() => {

                    this.router.navigate(['/', 'contract'])
                    .then(() => this._snackbar.open('CT_MESSAGES.success_operation'))

                });

        } else {

            Object.entries(<any>this.contractExtraField?.formContainer?.form?.controls).forEach(([key, control]) => {
                if(control instanceof FormControl){
                    if(control.errors){
                        this.contractExtraField?.setFormCustomError(true, '');
                        if(key == 'HardwareManteinance' || key == 'SoftwareMaintenance'){
                            this.contractExtraField?.setFormCustomError(true, 'Selezionare almeno un tipo di manutenzione');
                        }
                        if(key == 'lstSoftwareSubscriptionRowDTO'){
                            this.contractExtraField?.setFormCustomError(true, 'Compilare la tabella');
                        }
                    }
                }
            });

            this.formHelper.scrollToError();
        }

    }

    renewContract(contract: ContractDTO<T>){
        this.contractController
        .renewContract(contract)
        .subscribe((response: CtWebapiGenericResponse<number>) => {

            if (response.Result) {
                    this.router.navigate(['/']).then(() =>{
                        this.router.navigate(['/', 'contract', 'edit', (<any>response.Result)], {
                            onSameUrlNavigation: 'reload'
                        }).then(() => this._snackbar.open('CT_MESSAGES.new_contract_created'));
                    });
            }

        })

    }

    //Custom disable del form in modo da gestire i singoli campi
    disableForm(value: ContractDTO<any> | null = null) {
        this.contractDetailField?.automaticRenewControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.invoiceTypeControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.monthDurationControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.totalControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.currencyControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.validityStartControl?.control?.setDisabled(true);
        this.contractDetailField?.validityEndControl?.control?.setDisabled(true);
        this.contractDetailField?.contractStateControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.contractNumberControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.contractYearControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.orderDateControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.orderNumberControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.currencyControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.supplierControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.commercialControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.customerControlComponent?.control?.setDisabled(true);
        this.contractDetailField?.customerLocationControlComponent?.control?.setDisabled(true);

        if(value != null){
            this.contractInfoField
            ?.contractCodeCtrl?.setupForcedValue(value.ContractCode ?? "");
            this.contractDetailField?.customerLocationControlComponent?.control?.setValue(value.CustomerLocation);
        }

        this.contractInfoField?.form.disable();

        this.contractMonitoringField?.form.disable();

        this.contractExtraField?.setCustomFormDisabled(true);

        this.cancelButton
                .setLabel('Indietro');

        this.submitButton.setDisabled(true);
    }

}
