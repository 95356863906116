import { Component, OnInit } from '@angular/core';
import { CtControlConfiguration } from '@ctsolution/ct-framework';
import { CONTRACT_DESCRIPTION_CONTROL } from './contract-description.control';


@Component({
  selector: 'app-contract-description-control',
  template: `<ct-control [configuration]="control"></ct-control>`
})
export class ContractDescriptionControlComponent {

  control = CtControlConfiguration.create(CONTRACT_DESCRIPTION_CONTROL);

  constructor() { }

}
