import {
    CtButtonConfiguration,
    CtCardConfiguration,
    CtCardIconConfiguration,
    CtCardIconPosition, CtCardTemplate
} from "@ctsolution/ct-framework";

//# region BIG CARDS
export const NEW_CONTRACT_CARD: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Crea nuovo contratto")
    .setDescription("Componi un nuovo contratto e invialo")
    .setIconSetting(
        CtCardIconConfiguration
            .create()
            .setIcon("edit_document")
            .setClass("bg-success text-white")
            .setPosition(CtCardIconPosition.SUFFIX))
    .setClass("bg-megna text-white")
    .setButton(CtButtonConfiguration.create().setIcon("arrow_forward"));

export const CONTRACT_LIST_CARD: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Lista contratti")
    .setDescription("Visualizza tutti i tuoi contratti")
    .setIconSetting(
        CtCardIconConfiguration
            .create()
            .setClass("bg-warning text-white")
            .setIcon("file_open")
            .setPosition(CtCardIconPosition.SUFFIX))
    .setClass("bg-inverse text-white")
    .setButton(CtButtonConfiguration.create().setIcon("arrow_forward"));

//# endregion BIG CARDS

//# region CARDS

export const NEW_CUSTOMER_CARD: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Gestione clienti")
    .setDescription("Gestisci e verifica i dati dei tuoi clienti")
    .setIconSetting(
        CtCardIconConfiguration
            .create()
            .setIcon("account_circle")
            .setClass("bg-olive text-white"))

export const CONTRACT_TYPE_LIST: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Tipi di contratto")
    .setDescription("Inserisci e/o modifica i tipi di contratto")
    .setIconSetting(
        CtCardIconConfiguration
            .create()
            .setClass("bg-success text-white")
            .setIcon("settings"));
export const CONTRACT_OPTION_LIST: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Opzioni contratto")
    .setDescription("Gestisci le opzioni disponibili")
    .setIconSetting(
        CtCardIconConfiguration
            .create()
            .setClass("bg-inverse  text-white")
            .setIcon("flaky"));
export const BILLING_NOTIFICATION_TRIGGER: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Notifica fatturazione")
    .setClass('cursor')
    .setDescription("Aggiornamenti tempestivi riguardo l'emissione delle tue fatture per non perdere mai una scadenza importante.")
    .setIconSetting(
        CtCardIconConfiguration
            .create()
            .setClass("bg-warning  text-white")
            .setIcon("notification_important"));
export const GUARANTEE_NOTIFICATION_TRIGGER: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Notifica garanzie")
    .setDescription("Avvisi immediati riguardo la copertura e le scadenze delle tue garanzie.")
    .setClass('cursor')
    .setIconSetting(
        CtCardIconConfiguration
            .create()
            .setClass("bg-danger  text-white")
            .setIcon("campaign"));
//# endregion CARDS

//# region COUNTERS
export const ACTIVE_CONTRACTS_CARD: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Contratti attivi")
    .setTemplate(CtCardTemplate.COUNTER)
    .setClass("bg-megna text-white");
export const EXPIRING_CONTRACTS_CARD: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Contratti in scadenza")
    .setTemplate(CtCardTemplate.COUNTER)
    .setClass("bg-warning text-white")
export const EXPIRED_CONTRACTS_CARD: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Contratti scaduti")
    .setTemplate(CtCardTemplate.COUNTER)
    .setClass("bg-danger text-white");

export const ACTIVE_WARRANTY_CARD: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Garanzie attive")
    .setTemplate(CtCardTemplate.COUNTER)
    .setClass("bg-megna text-white");
export const EXPIRING_WARRANTY_CARD: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Garanzie in scadenza")
    .setTemplate(CtCardTemplate.COUNTER)
    .setClass("bg-warning text-white");
export const EXPIRED_WARRANTY_CARD: CtCardConfiguration = CtCardConfiguration
    .create()
    .setTitle("Garanzie scadute")
    .setTemplate(CtCardTemplate.COUNTER)
    .setClass("bg-danger text-white");

    //# endregion COUNTERS


