import { ContractDescriptionControlComponent } from './contract-description-control/contract-description-control.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContractInfoFieldComponent} from "./contract-info-field.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {CtControlModule} from "@ctsolution/ct-framework";
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import { ContractCodeControlComponent } from './contract-code-control/contract-code-control.component';
import { ContractTypeControlComponent } from './contract-type-control/contract-type-control.component';

@NgModule({
    declarations: [
        ContractInfoFieldComponent,
        ContractCodeControlComponent,
        ContractTypeControlComponent,
        ContractDescriptionControlComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        CtControlModule,
        ReactiveFormsModule,
        MatListModule
    ],
    exports: [
        ContractInfoFieldComponent
    ]
})
export class ContractInfoFieldModule {
}
